<template>
  <div id="register-page">
    <div class="container">
      <p class="login-title">注  册</p>
      <van-form @submit="onSubmit">
        <van-field
          size="large"
          v-model="phone"
          name="phone"
          label="手机号"
          placeholder="请输入手机号"
          :rules="[{ required: true, message: '请输入正确的手机号', pattern }]"
          class="cell"
        />
        <van-field
          size="large"
          v-model="username"
          name="username"
          label="用户名"
          placeholder="请输入用户名"
          :rules="[{ required: true, message: '用户名不能为空'}]"
          class="cell"
        />
        <van-field
          size="large"
          v-model="passwd"
          type="password"
          name="passwd"
          label="密码"
          placeholder="请输入密码"
          :rules="[{ required: true, message: '请填写密码' }]"
          class="cell"
        />
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">注册</van-button>
        </div>
        <p class="register">已有账号，<router-link :to="{name: 'login'}">去登录</router-link></p>
      </van-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'login',
  data () {
    return {
      phone: '',
      passwd: '',
      username: '',
      pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
    }
  },
  methods: {
    onSubmit () {
      this.$axios.post('/movie/register', {
        phone: this.phone,
        username: this.username,
        passwd: this.$md5(this.passwd)
      }).then(res => {
        if (res.data.code === 200) {
          this.$toast({
            type: 'success',
            message: '注册成功'
          })
          this.$router.push({
            name: 'login'
          })
        } else {
          this.$toast({
            type: 'fail',
            message: res.data.msg
          })
        }
      }).catch(res => {
        console.log(res)
      })
    }
  }
}
</script>
<style lang="less">
#register-page{
  align-items: center;
  justify-content: center;
  .container{
    .login-title{
      font-size: 30px;
      padding-top: 50px;
      text-align: center;
      margin-bottom: 20px;
    }
  }
  .cell{
    line-height:35px;
    font-size:16px;
  }
  .register{
    font-size: 16px;
    color: #999;
    text-align: right;
    padding-right: 25px;
    span {
      color: cadetblue;
    }
  }
}
</style>
